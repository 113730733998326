.section-banner {
  background-image: url('../../img/banner/banner.png');
  background-repeat: no-repeat;
  background-position: 80%;
}

.banner {
  &__wrapper {
    display: flex;
    justify-content: center;
    flex-flow: column;
    height: 531px;
  }
  &__title {
    color: $color;
    font-size: 56px;
    font-weight: 500;
  }
  &__subtitle {
    color: $color;
    font-size: 40px;
    margin-bottom: 32px;
    font-weight: 500;
  }
  &__desc {
    font-size: 24px;
  }
}

@media (max-width: 996px) {
  .section-banner {
    background-size: 50%;
  }
  .banner {
    &__wrapper {
      height: 80vh;
    }
  }
}

@media (max-width: 768px) {
  .section-banner {
    background-position: bottom;
    background-size: 100%;
  }
  .banner {
    &__wrapper {
      height: 565px;
      justify-content: flex-start;
      padding-top: 27px;
    }
    &__title {
      font-size: 40px;
      line-height: 140%;
    }
    &__subtitle {
      font-size: 24px;
      margin-bottom: 16px;
    }
    &__desc {
      font-size: 24px;
    }
  }
}