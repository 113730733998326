.section-footer {
  background-color: #E8EFF5;
  padding: 48px 0;
}

.footer {
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__menu {
    display: grid;
    grid-gap: 16px;
  }
  &__social {
    display: flex;
    flex-flow: column;
    grid-gap: 32px;
  }
  &__item {
    color: #303030;
    font-weight: 500;
  }
  &__info {
    display: flex;
    align-items: flex-end;
    flex-flow: column;
    text-align: right;
    grid-gap: 16px;

    &-item {
      display: flex;
      grid-gap: 8px;
    }

    &-text {
      color: #303030;
    }
  }
}

@media (max-width: 996px) {
  .footer {
    &__wrapper {
      grid-gap: 25px;
    }
  }
}

@media (max-width: 768px) {
  .section-footer {
    background-color: #fff;
  }

  .footer {
    &__wrapper {
      flex-flow: column;
    }
    &__logo {
      width: 170px;
      height: 79px;
    }
    &__info {
      text-align: left;
      align-items: flex-start;
    }
  }
}