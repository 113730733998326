.section-menu {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(226, 242, 255, 1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(226, 242, 255, 1);
  box-shadow: 0px 0px 15px 0px rgba(226, 242, 255, 1);
  background-color: #fff;
}
.menu {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }
  &__link {
    text-transform: uppercase;
    color: #303030;
    font-weight: 500;
    &_active,
    &:hover {
      color: $color;
      font-weight: 600;
      transition: all 0.2s ease-out;
    }
  }
}

@media (max-width: 996px) {
  .section-menu {
    display: none;
  }

  .menu {
    &__wrapper {
      height: auto;
      flex-flow: column;
      align-items: flex-start;
      grid-gap: 16px;
      margin-bottom: 30px;
    }
    &__link {
      display: block;
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid $color-three;
    }
  }
}