.why {
  &__wrapper {
    display: grid;
    grid-template-columns: 470px 1fr;
    grid-gap: 30px;
  }
  &__img {
    height: 470px;
    border-radius: 15px;

    -webkit-box-shadow: 0px 8px 25px 0px rgba(226, 242, 255, 1);
    -moz-box-shadow: 0px 8px 25px 0px rgba(226, 242, 255, 1);
    box-shadow: 0px 8px 25px 0px rgba(226, 242, 255, 1);
  }
  &__desc {
    margin-bottom: 16px;
  }
  &__number {
    &-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;

      margin: 33px 0;
    }
    &-name {
      color: $color;
      font-weight: 900;
      font-size: 45px;

      padding-bottom: 8px;
      margin-bottom: 16px;

      border-bottom: 1px solid $color;
    }
  }
  &__btn {
    width: 190px;
    height: 45px;
  }
}

@media (max-width: 1200px) {
  .why {
    &__number-name {
      font-size: 38px;
    }
  }
}

@media (max-width: 996px) {
  .why {
    &__wrapper {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 768px) {
  .why {
    &__number-wrapper {
      grid-template-columns: 1fr;
    }
    &__btn {
      width: 100%;
    }
  }
}