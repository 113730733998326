.agreement {
  display: flex;
  align-items: center;

  &__label {
    font-size: 11px;
    line-height: 12px;
    color: #7E7E7E;
  }
  
  &__checkbox {
    width: 12px;
    height: 12px;
    background-color: inherit;
    margin-right: 5px;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #7E7E7E;
  }

  &__checkbox:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%237E7E7E' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    background-color: inherit;
  }

  &__link {
    color: #7E7E7E;
    text-decoration: underline;
    opacity: 0.6;
  }
}

@media only screen and (max-width: 768px) {
  .agreement__label {
    font-size: 12px;
  }
}