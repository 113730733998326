.map {
  &__wrapper {
    position: relative;
    height: 410px;
    border-radius: 15px;
  }
  &__info {
    display: grid;
    grid-gap: 16px;
    width: 370px;
    padding: 24px;

    border-radius: 15px;
    border: 1px solid $color-three;
    position: absolute;

    background: #fff;
    opacity: .8;

    top: 25%;
    right: 10%;
  }
  &__item {
    display: flex;
    align-items: center;
    grid-gap: 16px;
  }
  &__text {
    color: #303030;
  }
}

#YaMaps {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .map {
    &__info {
      position: static;
      width: 100%;
      opacity: 1;
      margin-bottom: 32px;
    }
    &__wrapper {
      height: auto;
    }
  }
  #YaMaps {
    height: 410px;
  }
}