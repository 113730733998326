.product-card {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid $color;

  &_hidden {
    display: none !important;
  }

  &__img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 119px;
    margin-bottom: 24px;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &__header {
    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: $color;

      padding-bottom: 15px;
      margin-bottom: 15px;

      border-bottom: 1px solid $color-three;
    }
    &-name {
      font-size: 20px;
      font-weight: 600;
    }
    &-price {
      font-size: 24px;
      font-weight: 700;
    }
  }
  &__specifications {
    padding-bottom: 7px;
    margin-bottom: 15px;

    border-bottom: 1px solid $color-three;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      font-size: 16px;
      margin-bottom: 8px;
    }
  }
  &__btn {
    width: 100%;
    height: 45px;
  }
}