.certificate {
  &__wrapper {
    position: relative;
  }
  &__wrapper-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
}

@media (max-width: 996px) {
  .certificate {
    &__wrapper-img {
      height: auto;
    }
  }
}

@media (max-width: 768px) {
  .certificate {
    &__wrapper-img {
      height: 350px;
    }
    &__img {
      object-fit: contain;
    }
  }
}
