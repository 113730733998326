.controllers {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 4px;

  background-color: $color-three;

  position: absolute;
  top: 35%;
  z-index: 1000;

  &_next {
    transform: rotate(180deg);
    right: -70px;
  }
  &_prev {
    left: -70px;
  }
}

@media (max-width: 1350px) {
  .controllers {
    &_next {
      right: 0;
    }
    &_prev {
      left: 1px;
    }
  }
}

@media (max-width: 992px) {
  .controllers {
    position: relative;
    margin: 0 auto;

    &_next {
      top: -40px;
      left: 60px;
    }
    &_prev {
      left: -60px;
    }
  }
}