.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;

  &__primary {
    background-color: $color-second;
    color: #fff;
    &:hover {
      transition: all 0.2s ease-out;
      background-color: #AF010C;
    }
  }
}