.montage {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 470px;
    grid-gap: 40px;

    align-items: center;
  }
  &__img {
    border-radius: 15px;
    border: 1px solid $color-three;

    -webkit-box-shadow: 0px 8px 25px 0px rgba(226, 242, 255, 1);
    -moz-box-shadow: 0px 8px 25px 0px rgba(226, 242, 255, 1);
    box-shadow: 0px 8px 25px 0px rgba(226, 242, 255, 1);
  }
  &__info {
    display: grid;
    grid-gap: 18px;
  }
  &__info-item {
    display: flex;
    align-items: center;
    grid-gap: 16px;
  }
}

@media (max-width: 996px) {
  .montage {
    &__wrapper {
      grid-template-columns: 1fr;
    }
  }
}