$color: #004E9E;
$color-second: #E3000F;
$color-three: #D8EDFF;
$color-fourth: #F1F9FF;

/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  min-width: 320px;
  font-family: "Inter", sans-serif;
  min-height: 100vh;
  font-size: 18px;
  font-weight: 400;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: normal;
  color: #303030;
  background-image: url('../../img/background.jpg');
  background-repeat: no-repeat;
  background-position: top right;
}

@media (max-width: 768px) {
  body {
    background-image: url('../../img/background-mob.jpg');
  }
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
//ul[class],
//ol[class] {
//  list-style: none;
//}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

button {
  border: none;
  background-color: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

// custom!

ul {
  margin: 0;
  padding: 0;
}

li {
  padding: 0;
  margin: 0;
}

input {
  border: none;
}

.tns-controls {
  display: none;
}

.tns-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  margin-top: 35px;

  & button {
    width: 15px;
    height: 15px;
    background-color: $color-three;
    border-radius: 50px;
  }
  
  & .tns-nav-active {
    background-color: $color;
  }
}