.product {
  &__filter {
    display: flex;
    grid-gap: 15px;
    align-items: center;

    margin-bottom: 32px;
  }
  &__label {
    font-size: 16px;
    font-weight: 500;
  }
  &__filter-btn {
    border-radius: 10px;
    border: 1px solid $color-three;

    font-size: 14px;

    padding: 10px 20px;
  }
  &__wrapper {
    position: relative;
    &-hidden {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .product {
    &__filter {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }
    &__label {
      grid-column: 1/3;
      text-align: center;
    }
    &__filter-btn {
      padding: 10px;
    }
  }
}