.section-confidential {
  padding: 16px 0;
  border-top: 1px solid #C6C6C6;
  background-color: #E8EFF5;
}

.confidential {
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__item {
    font-size: 14px;
    text-decoration: underline;
    color: #303030;
  }
}

@media (max-width: 768px) {
  .section-confidential {
    background-color: #fff;
  }
  .confidential {
    &__wrapper {
      flex-flow: column;
      grid-gap: 21px;
    }
  }
}