.section-header {
  padding: 24px 0;
}

.header {
  &__logo {
    width: 200px;
    height: 92px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__phone {
    &-wrapper {
      display: flex;
      align-items: center;
      grid-gap: 5px;
    }
    &-text {
      font-weight: 600;
      font-size: 20px;
      color: #303030;
    }
  }
  &__btn {
    width: 190px;
    height: 45px;
  }
  &__wrapper-mob {
    display: none;
    background-color: #fff;
    padding: 16px 0;

    &_show {
      display: block;
    }
  }
  &__burger {
    display: none;
  }
}

@media (max-width: 1200px) {
  .header {
    &__phone-text {
      font-size: 14px;
    }
  }
}

@media (max-width: 996px) {
  .section-header {
    padding: 15px 0;
  }
  .header {
    &__burger {
      display: grid;
      flex-flow: column;
      width: 40px;

      grid-gap: 8px;
      & span {
        display: block;
        height: 3px;
        border-radius: 7px;

        background-color: $color;
        margin-left: auto;

        &:nth-child(1) {
          width: 100%;
        }
        &:nth-child(2) {
          width: 75%;
        }
        &:nth-child(3) {
          width: 30%;
        }
      }
    }
    &__phone-text {
      font-size: 16px;
    }
    &__phone-wrapper {
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 768px) {
  .header {
    &__logo {
      width: 96px;
      height: 45px;
    }
    &__btn {
      width: 150px;
      height: 45px;
      font-size: 13px;
    }
    &__burger {
      width: 30px;
    }
  }
}