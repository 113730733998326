//.input {
//  border-radius: 7px;
//  background: #FFF;
//  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.15);
//
//  padding-left: 22px;
//
//  font-size: 14px;
//  line-height: normal;
//
//  width: 100%;
//  height: 50px;
//
//  margin-bottom: 10px;
//
//  &::placeholder {
//    font-weight: 300;
//    font-size: 11px;
//    line-height: 12px;
//  }
//}

.input {
  width: 100%;
  height: 45px;

  border-radius: 7px;
  border: 1px solid $color-three;

  padding: 0 16px;

  &::placeholder {
    font-weight: 300;
    font-size: 14px;
  }
}