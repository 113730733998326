.section-feedback {
  background-image: url('../../img/feedback/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.feedback {
  &__wrapper {
    width: 570px;
    margin: 0 auto;
    padding: 48px;

    background: #fff;

    border: 1px solid $color-three;
    border-radius: 15px;

    position: relative;
  }
  &__title {
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 16px;
  }
  &__subtitle {
    font-weight: 500;
    margin-bottom: 24px;
  }
  &__form {
    display: grid;
    grid-gap: 24px;
  }
  &__btn {
    height: 45px;
  }
  &__remodal {
    background-color: transparent;
    padding: 0;
  }
  &__close {
    right: 0;
    left: auto;
  }
}

@media (max-width: 768px) {
  .section-feedback {
    background-image: none;
  }
  .feedback {
    &__wrapper {
      width: 100%;
      padding: 48px 16px;
    }
    &__title {
      font-size: 28px;
    }
  }
}