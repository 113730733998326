.ask {
  &__wrapper {
    border-radius: 10px;
    border: 1px solid $color-three;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 20px;

    height: 57px;
    border: 1px solid $color-three;
    background-color: $color-fourth;

    font-size: 20px;
    font-weight: 600;

    cursor: pointer;
  }
  &__item {
    &:nth-child(1) .ask__header {
      border-radius: 10px 10px 0 0;
    }
    &:nth-last-child(1) .ask__header {
      border-radius: 0 0 10px 10px;
    }
    &_active {
      & .ask__header {
        background-color: $color;
        color: #fff;
        font-weight: 700;
      }
      & .ask__icon {
        transform: rotate(180deg);

        & path {
          fill: white;
        }
      }
      & .ask__body {
        display: block;
      }
    }
  }
  &__body {
    display: none;
    padding: 16px 20px;

    & ul {
      padding-left: 25px;
    }
  }
}

@media (max-width: 768px) {
  .ask {
    &__header {
      height: auto;
      padding: 16px 20px;
    }
    &__body {
      line-height: 140%;
    }
  }
}