.advantages {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }
  &__card {
    padding: 64px 32px;
    border-radius: 15px;

    -webkit-box-shadow: 0px 8px 25px 0px rgba(226, 242, 255, 1);
    -moz-box-shadow: 0px 8px 25px 0px rgba(226, 242, 255, 1);
    box-shadow: 0px 8px 25px 0px rgba(226, 242, 255, 1);
  }
  &__img {
    width: 77px;
    height: 77px;
    margin-bottom: 17px;
  }
  &__title {
    font-size: 20px;

    font-weight: 600;
    height: 48px;
    margin-bottom: 16px;
  }
  &__desc {
    font-size: 16px;
  }
}

@media (max-width: 996px) {
  .advantages {
    &__wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
    &__card {
      padding: 30px;
      background-color: #ffffff;
    }
  }
}

@media (max-width: 768px) {
  .advantages {
    &__wrapper {
      grid-template-columns: 1fr;
    }
  }
}