.social {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
  }
  &_whatsapp:hover path:nth-child(1) {
    fill: #219653;
    transition: all 0.2s ease-out;
  }
  &_viber:hover path:nth-child(1) {
    fill: #6559A2;
    transition: all 0.2s ease-out;
  }
  &_telegram:hover path:nth-child(1) {
    fill: #26A2E0;
    transition: all 0.2s ease-out;
  }
  &_mail:hover path:nth-child(1) {
    fill: #003871;
    transition: all 0.2s ease-out;
  }
}