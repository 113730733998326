.buy {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 50px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    padding-right: 30px;
  }
  &__number {
    font-size: 45px;
    font-weight: 900;
    color: $color;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 80px;
    height: 80px;

    border-radius: 50px;
    border: 1px solid $color-three;

    background-color: #fff;
  }
  &__name {
    font-size: 20px;
    font-weight: 700;
    color: $color;

    margin-bottom: 16px;
  }
}

@media (max-width: 996px) {
  .buy {
    &__wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 768px) {
  .buy {
    &__wrapper {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
  }
}