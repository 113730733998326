.title {
  font-size: 40px;
  margin-bottom: 48px;
  text-align: center;
}

@media (max-width: 768px) {
  .title {
    font-size: 32px;
    margin-bottom: 32px;
  }
}
