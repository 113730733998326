.work {
  &__wrapper {
    position: relative;
  }
  &__img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;

    border-radius: 15px;
  }
}

@media (max-width: 768px) {
  .work {
    &__img-wrapper {
      height: 250px;
    }
  }
}