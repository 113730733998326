.section {
  &_pb {
    padding-bottom: 96px;
  }
  &_pt {
    padding-top: 96px;
  }
  &_color {
    background-color: $color-fourth;
  }
}

@media (max-width: 768px) {
  .section {
    &_pb {
      padding-bottom: 48px;
    }

    &_pt {
      padding-top: 48px;
    }
  }
}